<template>
  <div>
    <iframe
      :src="config.src"
      class="iframe-view"
    />
  </div>
</template>

<script>
export default {
  name: 'IframeView',
  props: {
    config: Object
  }
}
</script>

<style scoped>
.iframe-view {
  width: 100%;
  height: 98%;
  overflow: hidden;
  border: 0;
}
</style>